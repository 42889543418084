import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "gatsby";

const Cta = () => (
  <>
    {/* <!-- CTA section --> */}
    <div className="cta-section bg-default-2 ">
     
    </div>
  </>
);

export default Cta;
